<template>
  <div class="panel-order-product">
    <v-tabs v-model="tab" color="deep-blue accent-4" background-color="transparent" centered grow :show-arrows="false" :height="editable ? 0 : 20">
      <v-tab v-if="!editable" :disabled="editable" style="font-size:80%">
        {{ $t('order.tab.product.tab.product') }}
      </v-tab>
      <v-tab v-if="!editable" :disabled="editable" style="font-size:80%">
        {{ $t('order.tab.product.tab.preparation') }}
      </v-tab>
      <v-tab-item>
        <v-list flat subheader dense>
          <v-list-item v-for="(product, p) in products" :key="p" active-class="product-ready">
            <template #default="{ active }">
              <v-list-item-action>
                <v-checkbox color="accent" :input-value="active" />
              </v-list-item-action>
              <v-list-item-action v-if="!product.options || product.options.length === 0">
                <v-list-item-title>{{ product.quantity }} x </v-list-item-title>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <div><span v-if="product.options && product.options.length > 0">{{ product.quantity }} x </span>{{ product.name }}</div>
                </v-list-item-title>
                <v-list-item-subtitle v-if="product.options">
                  <ul>
                    <li v-for="(option, o) in product.options" :key="o">
                      {{ option.quantity }} x {{ option.name }}
                    </li>
                  </ul>
                  <div v-if="product.note" class="text-wrap font-italic font-weight-light black--text">
                    ({{ $t('order.tab.product.prop.note') }} {{ product.note }})
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>
          <v-list-item v-if="preparationNote" class="small">
            {{ $t('order.tab.product.prop.note') }} {{ preparationNote }}
          </v-list-item>
        </v-list>
      </v-tab-item>
      <v-tab-item>
        <v-list flat subheader dense>
          <v-list-item-group v-for="(group, g) in preparationGroups" :key="g" multiple>
            <v-subheader v-if="group.name">
              {{ group.name }}
            </v-subheader>
            <v-list-item v-for="(preparation, p) in group.preparations" :key="p" active-class="product-ready" @click="checkboxPreparationChange(String(g) + '-' + String(p))">
              <template #default="{ active }">
                <v-list-item-action>
                  <v-checkbox color="accent" :input-value="active" />
                </v-list-item-action>
                <v-list-item-action v-if="!preparation.options || preparation.options.length === 0">
                  <v-list-item-title>{{ formatQuantity(preparation.quantity) }} <sub v-if="preparation.quantityUnit">{{ preparation.quantityUnit }}</sub> x </v-list-item-title>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <div><span v-if="preparation.options && preparation.options.length > 0">{{ formatQuantity(preparation.quantity) }} x </span>{{ preparation.name }}</div>
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="preparation.options">
                    <ul>
                      <li v-for="(option, o) in preparation.options" :key="o">
                        {{ formatQuantity(option.quantity) }} <sub v-if="option.quantityUnit">{{ option.quantityUnit }}</sub> x {{ option.name }}
                      </li>
                    </ul>
                    <div v-if="preparation.note" class="text-wrap font-italic font-weight-light black--text">
                      ({{ $t('order.tab.product.prop.note') }} {{ preparation.note }})
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
          <v-list-item v-if="preparationNote" class="small">
            {{ $t('order.tab.product.prop.note') }} {{ preparationNote }}
          </v-list-item>
        </v-list>
      </v-tab-item>
      <v-tab-item class="pt-10 pb-5 text-center">
        <h4>{{ $t('order.tab.product.prop.waiting.title') }}</h4>
        <sub>{{ $t('order.tab.product.prop.waiting.subtitle') }}</sub>
        <div class="mt-5">
          <v-progress-circular :size="25" color="primary" indeterminate />
        </div>
      </v-tab-item>
    </v-tabs>
    <div v-if="$can('update', 'Order.Preparation')" class="my-2 text-center">
      <v-btn v-if="order.state.state === OrderState.placed" :disabled="loading" :loading="loading" color="secondary" @click.stop="orderInPreparation">
        {{ $t('order.button.preparation') }}
      </v-btn>
      <v-btn v-else-if="order.state.state === OrderState.preparation" :disabled="loading || !$can('update', 'CustomerService')" :loading="loading" color="accent" @click.stop="orderIsReadyDialog=true">
        {{ $t('order.button.ready') }}
      </v-btn>
    </div>
    <v-dialog v-model="orderIsReadyDialog" persistent max-width="500" :fullscreen="true">
      <v-card>
        <v-toolbar dense color="primary">
          <v-btn icon dark @click.stop="orderIsReadyDialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title class="text-h3 text-break">
          {{ $t('order.confirmation.ready.title', {orderName: order.name.toUpperCase()}) }}
        </v-card-title>
        <v-card-text><br></v-card-text>
        <v-card-actions>
          <v-btn color="secondary" @click.stop="orderIsReadyDialog=false">
            {{ $t('order.confirmation.ready.no') }}
          </v-btn>
          <v-spacer />
          <v-btn color="accent" @click.stop="orderIsReadyDialog=false, orderIsReady()">
            {{ $t('order.confirmation.ready.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { getPageName } from '@/services/common/Http';
  import OrderState from '@/services/order/OrderState';

  export default {
    name: 'OrderProductPanel',
    props: {
      order: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        tab: 0,
        editable: false,
        checked: {},
        products: [],
        preparationGroups: [],
        OrderState: OrderState,
        loading: false,
        orderIsReadyDialog: false
      };
    },
    computed: {
      preparationNote: function () {
        if (this.order.actions === undefined || this.order.actions.preparation === undefined) {
          return undefined;
        }

        return this.order.actions.preparation.note;
      }
    },
    watch: {
      order: function () {
        if (this.tab === 3) {
          this.selectTab();
        }
      }
    },
    mounted: function () {
      this.selectTab();
      this.editable = getPageName() === 'preparation' && this.$can('update', 'Order.Preparation');

      this.products = this.order.products ?? [];

      this.preparations = this.order.preparations ?? [];
      const preparationsByType = {};
      this.preparations.forEach((preparation) => {
        if (preparationsByType[String(preparation.type)] === undefined) {
          preparationsByType[String(preparation.type)] = { name: preparation.type, preparations: [preparation] };
        } else {
          preparationsByType[String(preparation.type)].preparations.push(preparation);
        }
      });
      this.preparationGroups = Object.values(preparationsByType);
      this.checked = {};
      for (let i = 0; i < this.preparationGroups.length; i++) {
        for (let j = 0; j < this.preparationGroups[i].preparations.length; j++) {
          this.checked[String(i) + '-' + String(j)] = false;
        }
      }
    },
    methods: {
      formatQuantity (quantity) {
        const result = String(quantity);
        const index = result.indexOf('.');
        if (index > 0) {
          return result.replace('.', ',') + '0'.repeat(Math.max(0, 3 - (result.length - index - 1)));
        }

        return result;
      },
      selectTab () {
        let newTab;
        if ([OrderState.placed].includes(this.order.state.state) && Array.isArray(this.order.preparations)) {
          newTab = 2;
        } else if ([OrderState.preparation].includes(this.order.state.state) && Array.isArray(this.order.preparations)) {
          newTab = 1;
        } else if (Array.isArray(this.order.products)) {
          newTab = 0;
        }

        if (newTab !== undefined && newTab !== this.tab) {
          this.tab = newTab;
        }
      },
      async orderInPreparation () {
        if (!this.loading) {
          this.setLoading(true);
          try {
            await this.$http.put(process.env.VUE_APP_BASE_API_URL + 'order/preparation', { orderId: this.order.id });
            this.$emit('success', this.order.id);
          } catch (error) {
            this.$emit('fail', error, this.$i18n.t('order.error'));
          }
          this.setLoading(false);
          this.tab = 1;
        }
      },
      async orderIsReady () {
        if (!this.loading) {
          this.setLoading(true);
          try {
            await this.$http.put(process.env.VUE_APP_BASE_API_URL + 'order/ready', { orderId: this.order.id });
            this.$emit('success', this.order.id);
          } catch (error) {
            this.$emit('fail', error, this.$i18n.t('order.error'));
          }
          this.setLoading(false);
        }
      },
      checkboxPreparationChange: function (checkboxId) {
        this.checked[checkboxId] = !this.checked[checkboxId];
        if (this.editable) {
          if (this.order.state.state === OrderState.placed) {
            this.orderInPreparation();
          }
          if (this.order.state.state === OrderState.preparation && Object.keys(this.checked).every((key) => this.checked[key])) {
            this.orderIsReady();
          }
        }
      },
      setLoading: function (isLoading) {
        if (isLoading) {
          this.loading = true;
        } else {
          setTimeout(() => {
            this.loading = false;
          }, 2000);
        }
      }
    }
  };
</script>
