<template>
  <v-container id="products" fluid tag="section">
    <alert-notification :message="alert" />
    <edit-product-option-form v-model="editProductOptionForm" :product-option="editedProductOption" @success="refreshData" @fail="fail" />
    <div v-if="$can('create', 'Admin.Meta.Product')" class="text-right">
      <v-btn color="secondary" :disabled="editProductOptionForm" :loading="editProductOptionForm" @click.stop="editedProductOption={},editProductOptionForm=true">
        <v-icon class="pr-2">
          mdi-food
        </v-icon>
        {{ $t('admin.sub.productOptions.button.new') }}
      </v-btn>
    </div>
    <v-card>
      <v-card-title>
        {{ $t('admin.sub.productOptions.title') }}
        <v-spacer />
        <v-text-field v-model="table.search" append-icon="mdi-magnify" :label="$t('admin.sub.productOptions.table.search')" single-line hide-details />
        <v-spacer />
        <v-btn icon medium dark color="black" :loading="loading" :disabled="loading" @click.stop="refreshData">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table :headers="table.headers" :options="table.options" :items="productOptions" :search="table.search" :loading="loading">
        <template #[`item.groups`]="{ item }">
          <span>{{ formatGroups(item.groups) }}</span>
        </template>
        <template #[`item.taxRates`]="{ item }">
          <span>{{ formatTaxRates(item.taxRates) }}</span>
        </template>
        <template #[`item.buyPrice`]="{ item }">
          <span>{{ formatBuyPriceTaxExcluded(item.prices) }}<br>{{ formatBuyPriceTaxIncluded(item.taxRates, item.prices) }}</span>
        </template>
        <template #[`item.sellPriceShopify`]="{ item }">
          <span>{{ formatSellPriceTaxExcluded(item.prices, 'shopify') }}<br>{{ formatSellPriceTaxIncluded(item.taxRates, item.prices, 'shopify') }}</span>
        </template>
        <template #[`item.sellPriceUberEats`]="{ item }">
          <span>{{ formatSellPriceTaxExcluded(item.prices, 'ubereats') }}<br>{{ formatSellPriceTaxIncluded(item.taxRates, item.prices, 'ubereats') }}</span>
        </template>
        <template #[`item.sellPriceDeliveroo`]="{ item }">
          <span>{{ formatSellPriceTaxExcluded(item.prices, 'deliveroo') }}<br>{{ formatSellPriceTaxIncluded(item.taxRates, item.prices, 'deliveroo') }}</span>
        </template>
        <template #[`item.sellPriceJustEat`]="{ item }">
          <span>{{ formatSellPriceTaxExcluded(item.prices, 'justeat') }}<br>{{ formatSellPriceTaxIncluded(item.taxRates, item.prices, 'justeat') }}</span>
        </template>
        <template #[`item.images`]="{ item }">
          <span>{{ item.images.length }}</span>
        </template>
        <template #[`item.tags`]="{ item }">
          <span>{{ item.tags.length }}</span>
        </template>
        <template #[`item.updatedAt`]="{ item }">
          <span>{{ formatDate(item.updatedAt) }}</span>
        </template>
        <template #[`item.actions`]="{ item }">
          <span v-if="$can('update', 'Admin.Meta.Product')">
            <v-icon medium @click="cloneProductOption(item)">
              mdi-file-multiple-outline
            </v-icon>
            <v-icon class="ml-2" medium @click="editedProductOption=item,editProductOptionForm=true">
              mdi-pencil
            </v-icon>
            <v-icon class="ml-2" medium @click="archiveProductOption(item)">
              mdi-archive
            </v-icon>
          </span>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import { displayDateISO } from '@/util/DateUtil';
  import { applyTaxRates } from '@/util/PriceUtil';
  import { handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';
  import EditProductOptionForm from '@/views/forms/product/EditProductOptionForm';

  export default {
    name: 'Products',
    components: { AlertNotification, EditProductOptionForm },
    data () {
      return {
        alert: '',
        editedProductOption: {},
        editProductOptionForm: false,
        loading: false,
        table: {
          headers: [
            {
              text: this.$i18n.t('admin.sub.productOptions.table.column.groups'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'groups'
            },
            {
              text: this.$i18n.t('admin.sub.productOptions.table.column.reference'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'reference'
            },
            {
              text: this.$i18n.t('admin.sub.productOptions.table.column.name'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'name'
            },
            {
              text: this.$i18n.t('admin.sub.productOptions.table.column.taxes'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'taxRates'
            },
            {
              text: this.$i18n.t('admin.sub.productOptions.table.column.buyPrice'),
              align: 'center',
              filterable: false,
              sortable: false,
              value: 'buyPrice'
            },
            {
              text: this.$i18n.t('admin.sub.productOptions.table.column.sellPriceShopify'),
              align: 'center',
              filterable: false,
              sortable: false,
              value: 'sellPriceShopify'
            },
            {
              text: this.$i18n.t('admin.sub.productOptions.table.column.sellPriceUberEats'),
              align: 'center',
              filterable: false,
              sortable: false,
              value: 'sellPriceUberEats'
            },
            {
              text: this.$i18n.t('admin.sub.productOptions.table.column.sellPriceDeliveroo'),
              align: 'center',
              filterable: false,
              sortable: false,
              value: 'sellPriceDeliveroo'
            },
            {
              text: this.$i18n.t('admin.sub.productOptions.table.column.sellPriceJustEat'),
              align: 'center',
              filterable: false,
              sortable: false,
              value: 'sellPriceJustEat'
            },
            {
              text: this.$i18n.t('admin.sub.products.table.column.images'),
              align: 'center',
              filterable: false,
              sortable: true,
              value: 'images'
            },
            {
              text: this.$i18n.t('admin.sub.products.table.column.tags'),
              align: 'center',
              filterable: false,
              sortable: true,
              value: 'tags'
            },
            {
              text: this.$i18n.t('admin.sub.productOptions.table.column.updatedAt'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'updatedAt'
            },
            {
              text: '',
              align: 'center',
              filterable: false,
              sortable: false,
              value: 'actions',
              width: '120px'
            }
          ],
          options: {
            page: 1,
            itemsPerPage: -1,
            sortBy: ['updatedAt'],
            sortDesc: [true],
            groupBy: [],
            groupDesc: [],
            multiSort: false,
            mustSort: true
          },
          search: ''
        },
        productOptions: []
      };
    },
    mounted: async function () {
      await this.refreshData();
    },
    methods: {
      formatGroups (groups) {
        if (groups === undefined || groups.length === 0) {
          return '';
        }

        return groups.map((group) => String(group.name)).join(', ');
      },
      formatTaxRates (taxRates) {
        if (taxRates === undefined || taxRates.length === 0) {
          return '';
        }

        return taxRates.map((rate) => String(rate * 100) + '%').join(', ');
      },
      formatBuyPriceTaxExcluded (prices) {
        if (prices === undefined || prices.length === 0) {
          return '';
        }

        const price = prices.find((price) => price.type === 'buy' && price.location === undefined);
        if (price === undefined) {
          return '';
        }

        return String(price.amount) + this.$i18n.t('common.price.currency.' + String(price.currency) + '.symbol') + ' ' + this.$i18n.t('common.price.taxExcluded.symbol');
      },
      formatBuyPriceTaxIncluded (taxRates, prices) {
        if (prices === undefined || prices.length === 0) {
          return '';
        }

        const price = prices.find((price) => price.type === 'buy' && price.location === undefined);
        if (price === undefined) {
          return '';
        }

        return String(applyTaxRates(price.amount, taxRates)) + this.$i18n.t('common.price.currency.' + String(price.currency) + '.symbol') + ' ' + this.$i18n.t('common.price.taxIncluded.symbol');
      },
      formatSellPriceTaxExcluded (prices, provider) {
        if (prices === undefined || prices.length === 0) {
          return '';
        }

        const price = prices.find((price) => price.type === 'sell' && price.location === undefined && price.provider === provider);
        if (price === undefined) {
          return '';
        }

        return String(price.amount) + this.$i18n.t('common.price.currency.' + String(price.currency) + '.symbol') + ' ' + this.$i18n.t('common.price.taxExcluded.symbol');
      },
      formatSellPriceTaxIncluded (taxRates, prices, provider) {
        if (taxRates === undefined || taxRates.length === 0 || prices === undefined || prices.length === 0) {
          return '';
        }

        const price = prices.find((price) => price.type === 'sell' && price.location === undefined && price.provider === provider);
        if (price === undefined) {
          return '';
        }

        return String(applyTaxRates(price.amount, taxRates)) + this.$i18n.t('common.price.currency.' + String(price.currency) + '.symbol') + ' ' + this.$i18n.t('common.price.taxIncluded.symbol');
      },
      formatDate: displayDateISO,
      async archiveProductOption (productOption) {
        if (confirm(this.$i18n.t('admin.sub.products.form.confirmation.archive', { name: productOption.name }))) {
          this.loading = true;
          try {
            await this.$http.put(process.env.VUE_APP_BASE_API_URL + 'product/option/status', { productOptionId: productOption.id, status: 'archived' });
            this.alert = '';
          } catch (error) {
            this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('admin.error.fail'));
          }
          this.loading = false;

          await this.refreshData();
        }
      },
      async cloneProductOption (productOption) {
        if (confirm(this.$i18n.t('admin.sub.products.form.confirmation.clone', { name: productOption.name }))) {
          this.loading = true;
          try {
            await this.$http.post(process.env.VUE_APP_BASE_API_URL + 'product/option/clone', { productOptionId: productOption.id });
            this.alert = '';
          } catch (error) {
            this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('admin.error.fail'));
          }
          this.loading = false;

          await this.refreshData();
        }
      },
      async refreshData () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'product/options/', { timeout: 60000 });
          if (response.data !== undefined && response.data.options !== undefined) {
            this.productOptions = response.data.options;
          }
          this.alert = '';
        } catch (error) {
          this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('admin.error.fail'));
        }
        this.loading = false;
      },
      async fail (error, message) {
        this.alert = await handleRequestError(error, this.$router, this.$i18n, message);
        ;
      }
    }
  };
</script>
